<template>
  <div class="search" v-if="!isGetting">
    <template v-if="!hasTags && !hasDisplayedTag">
      <p class="search__text">Hãy chọn tag liên quan đến sở thích của bạn.</p>
      <h2 class="search__title">Trình độ</h2>
      <div class="search__tag" v-for="level in ['N5', 'N4', 'N3', 'N2']" :key="level">
        <input class="search__tag__input" type="checkbox" :id=level :value=level v-model="selected.levels">
        <label :class="selected.levels.includes(level) ? 'search__tag__label--active' : 'search__tag__label'" :for=level>{{ level }}</label>
      </div>
      <h2 class="search__title">Chủ đề</h2>
      <div class="search__tag">
        <label :class="selected.topics.includes('all') ? 'search__tag__label--active' : 'search__tag__label'" @click="selectAll('topic')">All</label>
      </div>
      <div class="search__tag" v-for="id in Object.keys(topics)" :key="id">
        <input class="search__tag__input" type="checkbox" :id=id :value=topics[id].name v-model="selected.topics">
        <label :class="selected.topics.includes(topics[id].name) ? 'search__tag__label--active' : 'search__tag__label'"
        :for=id @click="selectTags('topic')">{{ topics[id].name }}</label>
      </div>
      <h2 class="search__title">Nghề nghiệp</h2>
      <div class="search__tag">
        <label :class="selected.occupations.includes('all') ? 'search__tag__label--active' : 'search__tag__label'" @click="selectAll('occupation')">All</label>
      </div>
      <div class="search__tag" v-for="id in Object.keys(occupations)" :key="id">
        <input class="search__tag__input" type="checkbox" :id=id :value=occupations[id].name v-model="selected.occupations">
        <label :class="selected.occupations.includes(occupations[id].name) ? 'search__tag__label--active' : 'search__tag__label'"
        :for=id @click="selectTags('occupation')">{{ occupations[id].name }}</label>
      </div>
      <button class="search__btn" @click="search()">Tìm kiếm</button>
    </template>
    <div class="search__results" v-else>
      <!-- 検索画面へ戻る -->
      <img class="search__results__back" src="@/assets/img/search.svg" @click="$router.push({ name: 'Search' })">
      <div class="search__results__tag">
        <!-- 日本語レベル -->
        <div :class="selected.levels.includes(level) ? 'search__results__tag__name--active' : 'search__results__tag__name'" v-for="level in displayed.levels"
        :key="level" @click="touchTag('level', level)">{{ level }}</div>
        <!-- トピック -->
        <div :class="selected.topics.includes(topic) ? 'search__results__tag__name--active' : 'search__results__tag__name'" v-for="topic in displayed.topics"
        :key="'topic' + topic" @click="touchTag('topic', topic)">{{ topic === 'all' ? 'Topic All' : topic }}</div>
        <!-- 職業 -->
        <div :class="selected.occupations.includes(occupation) ? 'search__results__tag__name--active' : 'search__results__tag__name'" v-for="occupation in displayed.occupations"
        :key="'occupation' + occupation" @click="touchTag('occupation', occupation)">{{ occupation === 'all' ? 'Occupation All' : occupation }}</div>
      </div>
      <img v-if="isSearching" class="search__results__processing" src="@/assets/img/processing.gif">
      <template v-else-if="!isSearching && results.length > 0">
        <h2 class="search__results__text">
          Kết quả tìm kiếm : {{ results.length }} kết quả
        </h2>
        <video-list :movies=results></video-list>
      </template>
      <div v-else class="search__results__none">
        <h2 class="search__results__text">Kết quả tìm kiếm : 0 kết quả</h2>
        <img class="search__results__none__img" src="@/assets/img/settings-gray.svg">
        <p class="search__results__none__text">Chúng tôi không tìm được kết quả video nào.</p>
      </div>
    </div>
  </div>
</template>

<script>
import VideoList from '@/components/video/list'

import { mapActions } from 'vuex'
export default {
  components: { VideoList },
  data () {
    return {
      // 選択中の日本語レベル、トピック、職業
      selected: {
        levels: [],
        topics: [],
        occupations: []
      },
      // 表示中の日本語レベル、トピック、職業
      displayed: {
        levels: [],
        topics: [],
        occupations: []
      },
      // 検索結果
      results: [],
      // 情報を取得中かどうか
      isGetting: true,
      // 検索中かどうか
      isSearching: true
    }
  },
  mounted () {
    // 全トピックと職業情報の取得
    Promise.all([this.getTopics(), this.getOccupations()]).then(() => {
      this.isGetting = false
    })

    // 検索タグの設定と検索の実施
    if (this.hasTags) this.initProcess()
  },
  watch: {
    '$route.query' (newQuery) {
      // クエリがない場合は初期の検索は行わない
      if (Object.keys(newQuery).length === 0) {
        Object.keys(this.displayed).forEach(key => {
          this.displayed[key] = []
        })
      } else {
        this.initProcess()
      }
    }
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {Object} トピック一覧
     */
    topics () {
      return this.$store.getters['topic/topics']
    },
    /**
     * @return {Object} 職業一覧
     */
    occupations () {
      return this.$store.getters['occupation/occupations']
    },
    /**
     * @return {Boolean} 検索タグを持っているかどうか
     */
    hasTags () {
      const hasLevels = this.$route.query.levels !== undefined
      const hasTopics = this.$route.query.topics !== undefined
      const hasOccupations = this.$route.query.occupations !== undefined
      return hasLevels || hasTopics || hasOccupations
    },
    /**
     * @return {Boolean} 表示タグが格納されているか
     */
    hasDisplayedTag () {
      const hasLevels = this.displayed.levels.length > 0
      const hasTopics = this.displayed.topics.length > 0
      const hasOccupations = this.displayed.occupations.length > 0
      return hasLevels || hasTopics || hasOccupations
    }
  },
  methods: {
    ...mapActions('topic', ['getTopics']),
    ...mapActions('occupation', ['getOccupations']),
    ...mapActions('movie', ['searchMovies']),
    /**
     * 初期処理
     * 検索タグの設定と検索
     */
    initProcess () {
      this.isSearching = true
      // 選択されたタグを抽出
      const tags = {
        levels: this.$route.query.levels.length === 0 ? [] : this.$route.query.levels.split(',').sort((a, b) => a > b ? -1 : 1),
        topics: this.$route.query.topics.length === 0 ? [] : this.$route.query.topics.split(',').sort((a, b) => a > b ? -1 : 1),
        occupations: this.$route.query.occupations.length === 0 ? [] : this.$route.query.occupations.split(',').sort((a, b) => a > b ? -1 : 1)
      }

      // タグの格納
      const hasDisplayedTag = this.hasDisplayedTag
      Object.keys(tags).forEach(tagName => {
        this.selected[tagName] = tags[tagName]
        if (!hasDisplayedTag || this.$route.query.from === 'tag') this.displayed[tagName] = tags[tagName]
      })

      // 検索
      this.searchMovies({
        levels: this.selected.levels,
        topics: this.selected.topics,
        occupations: this.selected.occupations
      }).then(results => {
        this.results = results
        this.isSearching = false
      })
    },
    /**
     * 検索タグを選択
     * allが選択されていたら解除する
     * @param {String} tagName タグの名前 topic、occupation
     */
    selectTags (tagName) {
      if (this.selected[tagName + 's'].includes('all')) this.selected[tagName + 's'] = []
    },
    /**
     * Allタグを選択
     * allが選択されていたら解除、選択されていなければ選択して他のタグは解除
     * @param {String} tagName タグの名前 topic、occupation
     */
    selectAll (tagName) {
      this.selected[tagName + 's'] = this.selected[tagName + 's'].includes('all') ? [] : ['all']
    },
    /**
     * 選択されたタグに応じて検索
     */
    search () {
      this.isSearching = true
      this.searchMovies({
        levels: this.selected.levels,
        topics: this.selected.topics,
        occupations: this.selected.occupations
      }).then(results => {
        this.results = results
        this.isSearching = false
      })

      if (Object.values(this.selected).filter(tag => tag.length > 0).length === 0) {
        this.displayed.levels = ['N5', 'N4', 'N3', 'N2']
        this.displayed.topics = Object.keys(this.topics).map(key => this.topics[key].name)
        this.displayed.occupations = Object.keys(this.occupations).map(key => this.occupations[key].name)
      } else {
        this.displayed.levels = this.selected.levels.sort((a, b) => a > b ? -1 : 1)
        this.displayed.topics = this.selected.topics.sort((a, b) => a > b ? -1 : 1)
        this.displayed.occupations = this.selected.occupations.sort((a, b) => a > b ? -1 : 1)
      }

      // 検索結果のページへ遷移
      this.$router.push({
        name: 'Search',
        query: {
          uid: this.uid,
          levels: this.selected.levels.join(','),
          topics: this.selected.topics.join(','),
          occupations: this.selected.occupations.join(',')
        }
      })
    },
    /**
     * タグの解除or再選択と再検索
     * 選択中のタグとクエリの変更
     * @param {String} tagName タグの名前 level、topic、occupation
     * @param {String} tag 解除対象のタグ名
     */
    touchTag (tagName, tag) {
      // タグの解除か再選択
      if (this.selected[tagName + 's'].includes(tag)) { // タッチしたタグが選択済みの場合
        this.selected[tagName + 's'] = this.selected[tagName + 's'].filter(elem => elem !== tag)
      } else { // タッチしたタグが未選択の場合
        this.selected[tagName + 's'].push(tag)
      }

      // 再検索
      if (this.hasTags) { // 選択中のタグが存在しない場合は、検索が不要なので、この条件がある
        this.isSearching = true
        this.searchMovies({
          levels: this.selected.levels,
          topics: this.selected.topics,
          occupations: this.selected.occupations
        }).then(results => {
          this.results = results
          this.isSearching = false
        })
      }

      // クエリ情報の更新
      this.$router.push({
        name: 'Search',
        query: {
          uid: this.uid,
          levels: this.selected.levels.join(','),
          topics: this.selected.topics.join(','),
          occupations: this.selected.occupations.join(',')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.search {
  width: 100%;
  margin-bottom: $footer_height;
  padding: 0 $main_padding;
  &__text {
    width: 72.5%;
    margin: 0;
    font-size: 15px;
    color: #393939;
  }
  &__title {
    margin-top: 20px;
    margin-bottom: 9px;
    font-size: 16px;
    font-weight: bold;
    color: #393939;
  }
  &__tag {
    display: inline-block;
    vertical-align: middle;
    &__input {
      display: none;
    }
    &__label {
      display: block;
      height: 30px;
      min-width: 90px;
      margin-right: 8px;
      margin-bottom: 10px;
      padding: 0 32.6px;
      font-size: 15px;
      line-height: 30px;
      color: white;
      border-radius: 20px;
      background-color: #d2d2d2;
      text-align: center;
      box-sizing: border-box;
      &--active {
        @extend .search__tag__label;
        background-color: #ff9534;
      }
    }
  }
  &__btn {
    height: 44px;
    width: 100%;
    margin-top: 40px;
    border-radius: 36px;
    background-color: #1cc2a6;
    font-size: 17px;
    color: white;
  }
  &__results {
    position: relative;
    &__back {
      position: absolute;
      top: calc(-#{$header_padding_bottom} - 30px);
      right: 0;
      height: 23.9px;
      width: 23.9px;
      z-index: 20;
    }
    &__tag {
      width: calc(100vw - #{$main_padding});
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar{
        display:none;
      }
      &__name {
        display: inline-block;
        vertical-align: middle;
        height: 25.3px;
        min-width: 80px;
        margin-right: 5px;
        padding: 0 32px;
        border-radius: 20px;
        font-size: 12px;
        line-height: 25.3px;
        color: white;
        background-color: #d2d2d2;
        &--active {
          @extend .search__results__tag__name;
          background-color: #ff9534;
        }
      }
    }
    &__text {
      margin: 0;
      margin-top: 24.7px;
      font-size: 20px;
      font-weight: bold;
      color: #393939;
      text-align: left;
    }
    &__none {
      width: 100%;
      text-align: center;
      &__img {
        margin-top: 79px;
        width: 151.1px;
      }
      &__text {
        margin: 0;
        margin-top: 10.4px;
        font-size: 15px;
        font-weight: bold;
        color: #d4d4d4;
      }
    }
    &__processing {
      width: 40%;
      margin-top: 100px;
      margin-left: 30%;
    }
  }
}
</style>
